import React, { FC, useEffect, useState } from 'react';
import Modal from '../../molecules/Modal';
import Collection from '../pages/CollectionContent/Collection';
import LogoDetail from '../../atoms/Logo/LogoDetail';
import useModal from '../../../hooks/useModal';
import useLogoLounge from '../../../hooks/useLogoLounge';
import { ILogo } from '../../../types/logos.types';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ICollectionDetail {
  closeDetailModal: () => void;
  logos: ILogo[];
  actionCallback?: any;
  setIsNavigatingInModal?: (isNavigating: boolean) => void; // Make this optional
}

const CollectionDetailModal: FC<ICollectionDetail> = ({
  closeDetailModal,
  logos,
  actionCallback,
  setIsNavigatingInModal, // Accept the new prop
}) => {
  const [saveMode, setSaveMode] = useState(false);
  const { closeModal, type } = useModal();
  const {
    logoIndex,
    setLogoIndex,
    logoId,
    setLogoId,
    setHidePrev,
    setHideNext,
    setSearchValues,
  } = useLogoLounge();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const onPrevClick = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (logoIndex > 0) {
      setIsNavigatingInModal?.(true); // Mark as navigating in the modal
      const newLogoId = logos[logoIndex - 1]?.logo_id;
      if (newLogoId) {
        setLogoIndex(prevIndex => prevIndex - 1);
        setLogoId(newLogoId);
        const newSearchParams = searchParams;
        newSearchParams.set('logo_id', newLogoId.toString());
        setSearchParams(newSearchParams);
      }
    }
  };

  const onNextClick = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (logoIndex < logos.length - 1) {
      setIsNavigatingInModal?.(true); // Mark as navigating in the modal
      const newLogoId = logos[logoIndex + 1]?.logo_id;
      if (newLogoId) {
        setLogoIndex(prevIndex => prevIndex + 1);
        setLogoId(newLogoId);
        const newSearchParams = searchParams;
        newSearchParams.set('logo_id', newLogoId.toString());
        setSearchParams(newSearchParams);
      }
    }
  };

  const onCloseModal = () => {
    closeModal();
    setIsNavigatingInModal?.(false); // Ensure modal navigation flag is reset when closing modal
    setHidePrev(false);
    setHideNext(false);
    const newSearchParams = searchParams;
    newSearchParams.delete('logo_id');
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (searchParams.has('logo_id')) {
      if (logos && logos.length > 0) {
        const index = logos.findIndex(
          logo => logo.logo_id === parseInt(searchParams.get('logo_id') || '-1')
        );
        if (index > -1) {
          setLogoIndex(index);
          setLogoId(parseInt(searchParams.get('logo_id') || '-1'));
          setHidePrev(index === 0);
          setHideNext(index === logos.length - 1); // Hide the next button if at the last logo
        } else {
          navigate('/search');
        }
      }
    } else {
      closeModal();
    }
    // eslint-disable-next-line
  }, [searchParams, logos]);

  return (
    <Modal
      type={type}
      saveMode={saveMode}
      onClose={() => onCloseModal()}
      header={type === 'collection' ? 'Save to Collection' : ''}
      onPrevClick={onPrevClick}
      onNextClick={logoIndex < logos.length - 1 ? onNextClick : undefined} // Disable next click if at the last logo
      actions={
        <>
          <button type="submit" form="add-to-collection" className="done_button close">
            Done
          </button>
          <label
            className="new_collection_button"
            onClick={() => setSaveMode(oldState => !oldState)}
          >
            Create New
          </label>

          <button
            className="save_new_collection_button"
            type="submit"
            form="new-collection-form"
            tabIndex={0}
          >
            Save Collection
          </button>
        </>
      }
    >
      {type === 'collection' ? (
        <Collection
          actionCallback={actionCallback}
          closeDetailModal={closeDetailModal}
          logoId={logoId}
        />
      ) : (
        <LogoDetail
          logoId={logoId}
          logoIndex={logoIndex}
          actionCallback={actionCallback}
          setSearchValues={setSearchValues}
        />
      )}
    </Modal>
  );
};

export default CollectionDetailModal;
