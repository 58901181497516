import React, { useState, useEffect } from 'react';
import Layout from '../components/molecules/Layout';
import Container from '../components/atoms/Container';
import CollectionDetailModal from '../components/features/Modals/CollectionDetailModal';
import useLogoLounge from '../hooks/useLogoLounge';
import useModal from '../hooks/useModal';
import { ILogo } from '../types/logos.types';
import { searchLogos } from '../services/logo';
import { useSearchParams } from 'react-router-dom';
import Logo from '../components/atoms/Logo/Logo';
import SearchChips from '../components/molecules/SearchChips';
import Skeleton from '../components/atoms/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import useToast from '../hooks/useToast';
import { SKELETON_ITEMS } from '../utils/dummyData';

const Search = () => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setIsSearchOpen } = useLogoLounge();
  const { closeModal, openModal, isModalOpen } = useModal();

  const page_size = 30;
  const initialPage = parseInt(searchParams.get('page') || '1', 10); // Get initial page from URL
  const [count, setCount] = useState(0);
  const [logos, setLogos] = useState<ILogo[][]>([]); // Array of arrays for each page
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false); // Prevents multiple fetches
  const [currentPage, setCurrentPage] = useState(initialPage); // Track current page
  const [hasMore, setHasMore] = useState(true); // Track if there are more logos to fetch

  // Flag to prevent re-fetching due to modal interactions
  const [isNavigatingInModal, setIsNavigatingInModal] = useState(false);

  // Handle sort change
  const handleSortChange = (newSort: string) => {
    const div = document.querySelector('#root-outer-div');
    div?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const newSearchParams = searchParams;
    newSearchParams.set('sort', newSort);
    newSearchParams.delete('page');
    newSearchParams.delete('logo_id');
    setSearchParams(newSearchParams);
    setCurrentPage(1); // Reset page number on sort change
    setLogos([]); // Reset the logos list on sort change
    setHasMore(true); // Reset 'has more' status
    refreshLogos(true); // Fetch new logos based on the new sort
  };

  // Update the URL with the current page number
  useEffect(() => {
    if (!isFetchingMore) {
      const newSearchParams = searchParams;
      newSearchParams.set('page', currentPage.toString());
      setSearchParams(newSearchParams);
    }
  }, [currentPage]);

  // Fetch logos from the server
  const refreshLogos = (resetPage: boolean = false) => {
    if (isNavigatingInModal) return; // Don't fetch while navigating in modal
    setIsLoading(true);

    const page = resetPage ? 1 : currentPage;

    searchLogos({
      page,
      page_size: page_size,
      search_text: searchParams.get('keyword') || '',
      filter_category: searchParams.get('class') || '',
      filter_type: searchParams.get('type') || '',
      search_client: searchParams.get('client') || '',
      search_designer: searchParams.get('designer') || '',
      search_industry: searchParams.get('industry') || '',
      filter_country: searchParams.get('country') || '',
      sort: searchParams.get('sort') || 'recent',
    })
      .then(data => {
        if (data) {
          // If resetting (i.e., after a sort change or initial load), replace logos
          if (resetPage) {
            setLogos([data.data]);
          } else {
            setLogos(prevLogos => [...prevLogos, data.data]); // Append new page of logos
          }
          setCount(data.count);
          setHasMore(data.data.length > 0); // Set hasMore to false if no more logos to fetch
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error fetching logos.',
          });
        }
        setIsLoading(false);
        setIsFetchingMore(false); // Fetching completed
      })
      .catch(error => {
        setIsLoading(false);
        setIsFetchingMore(false); // Ensure fetch state is reset on error
        toast.addToast({
          type: 'error',
          message: 'Error fetching logos.',
        });
      });
  };

  // Handle infinite scroll: load more logos
  const handlePageChange = () => {
    if (isFetchingMore || !hasMore) return; // Prevent duplicate fetches if already fetching or no more data
    setIsFetchingMore(true);
    setCurrentPage(prevPage => prevPage + 1); // Increment page number for the next fetch
  };

  // Handle logo click to open modal
  const handleLogoClick = (logoId: number) => {
    setIsNavigatingInModal(true); // Mark as navigating in modal
    const newSearchParams = searchParams;
    newSearchParams.set('logo_id', logoId.toString());
    setSearchParams(newSearchParams);
    openModal();
  };

  // Initial fetch of logos on component mount (respecting the page from the URL)
  useEffect(() => {
    refreshLogos(false); // Fetch logos for the current page from URL
  }, [initialPage]); // Fetches logos based on the page from URL only on mount

  // Fetch more logos when page changes (due to infinite scroll)
  useEffect(() => {
    if (currentPage > initialPage) {
      refreshLogos(); // Fetch more logos when the page changes
    }
  }, [currentPage]);

  return (
    <Layout className="search">
      <div className="search_outer">
        <main id="content">
          <Container>
            <div className="results_header">
              <SearchChips onClick={() => setIsSearchOpen(true)} />
              <div className="sort_outer">
                <select
                  id="sort"
                  title="Sort"
                  onChange={event => handleSortChange(event.target.value)}
                  value={searchParams.get('sort') || 'recent'}
                >
                  <option value="recent">Most Recent</option>
                  <option value="popular">Most Popular</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            {!isLoading && <div className="results_count_top">{count.toLocaleString()} logos</div>}
            <div className="logos_outer">
              <ul>
                <InfiniteScroll
                  next={() => {
                    !isLoading && handlePageChange(); // Prevent duplicate fetches
                  }}
                  hasMore={hasMore} // Prevent additional fetches if no more logos to load
                  loader="" // loader="Loading more logos..."
                  dataLength={logos.flat().length} // Flatten the array to get the total count of logos
                >
                  {logos.map((pageLogos, pageIndex) => (
                    pageLogos.length > 0 && ( // Only show page separator if there are logos
                      <React.Fragment key={`page-${pageIndex}`}>
                        {/* Page Separator */}
                        <div className="page-separator">
                          <hr />
                          <span className="page-number">Page {pageIndex + initialPage}</span> 
                          {/* Fix the page number to show the actual page */}
                        </div>

                        {/* Display logos for the current page */}
                        {pageLogos.map((logo: any, index: number) => (
                          <li key={`logo-${pageIndex}-${index}`} onClick={() => handleLogoClick(logo.logo_id)}>
                            <Logo
                              logo={logo}
                              section="fresh"
                              index={index}
                              bookAward={logo.book_id ? `book-${logo.book_id}` : ''}
                              masterBookAward={logo.master_book_id ? `master-book-${logo.master_book_id}` : ''}
                            />
                          </li>
                        ))}
                      </React.Fragment>
                    )
                  ))}
                  {isLoading &&
                    Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                      <li key={`logo-skeleton-${index}`} className="loading">
                        <Skeleton className="square" />
                      </li>
                    ))}
                </InfiniteScroll>
              </ul>
            </div>

            {isModalOpen && (
              <CollectionDetailModal
                closeDetailModal={() => {
                  closeModal();
                  setIsNavigatingInModal(false); // Reset modal navigation flag on close
                }}
                logos={logos.flat()} // Pass flat array of logos to the modal
                setIsNavigatingInModal={setIsNavigatingInModal} // Pass function to update state
              />
            )}
          </Container>
        </main>
      </div>
    </Layout>
  );
};

export default Search;